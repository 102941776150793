<template>
    <SimpleTable formclass="Region/form" endpoint="regions" :aditionalColumns="headers">
        <template>
            <h1>Região</h1>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Endereço"), value: "address", align: "right", sortable: true },
                { text: this.$t("Cidade"), value: "city", align: "right", sortable: true },
                { text: this.$t("Bairro"), value: "district", align: "right", sortable: true },
                { text: this.$t("Região"), value: "region", align: "right", sortable: true },
                { text: this.$t("Inicio"), value: "zipCodeStart", align: "right", sortable: true },
                { text: this.$t("Fim"), value: "zipCodeStop", align: "right", sortable: true },
            ],
        };
    },
};
</script>